"use client";

import { usePathname } from "next/navigation";
import { ReactNode, useEffect } from "react";
import { useMediaQuery } from "usehooks-ts";

export default function Script(): ReactNode {
  const pathname = usePathname();
  const isDesktop = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    if (!isDesktop) return;

    const header = document.getElementById("header");
    if (!header) return;

    if (pathname.startsWith("/checkout")) {
      header.style.display = "none";
      header.style.visibility = "hidden";
      header.setAttribute("aria-hidden", "true");
    } else {
      header.style.display = "flex";
      header.style.visibility = "visible";
      header.setAttribute("aria-hidden", "false");
    }
  }, [isDesktop, pathname]);

  useEffect(() => {
    const check = () => {
      if (!isDesktop) return;

      const header = document.getElementById("header");
      if (!header) return;

      if (pathname === "/") {
        header.style.backgroundColor = "#fff";

        const headerIcons = document.querySelectorAll(".header-icon");

        for (let i = 0; i < headerIcons.length; i++) {
          const icon = headerIcons[i] as HTMLElement;
          icon.style.color = "black";
        }

        return;
      }

      let scrollLimit = 25;

      if (window.scrollY > scrollLimit) {
        const opacity = Math.min(
          1,
          (window.scrollY - scrollLimit) / scrollLimit,
        );
        header.style.backgroundColor = `rgba(255, 255, 255, ${opacity})`;
        header.style.zIndex = "30";
      } else {
        header.style.backgroundColor = "transparent";
        header.style.zIndex = "10";
      }

      const headerIcons = document.querySelectorAll(".header-icon");
      const headerTitle = document.getElementById("header-title");

      if (pathname.startsWith("/series")) {
        for (let i = 0; i < headerIcons.length; i++) {
          const icon = headerIcons[i] as HTMLElement;
          icon.style.color = window.scrollY > scrollLimit ? "black" : "white";
        }
        if (!headerTitle) return;
        headerTitle.style.color =
          window.scrollY > scrollLimit ? "black" : "white";
      } else {
        for (let i = 0; i < headerIcons.length; i++) {
          const icon = headerIcons[i] as HTMLElement;
          icon.style.color = "black";
        }
        if (!headerTitle) return;
        headerTitle.style.color = "black";
      }
    };

    check();

    window.addEventListener("load", check);
    window.addEventListener("scroll", check);
    window.addEventListener("resize", check);
    window.addEventListener("orientationchange", check);
    window.addEventListener("popstate", check);

    return () => {
      window.removeEventListener("load", check);
      window.removeEventListener("scroll", check);
      window.removeEventListener("resize", check);
      window.addEventListener("orientationchange", check);
      window.addEventListener("popstate", check);
    };
  }, [isDesktop, pathname]);

  return null;
}
