"use client";

import { ReactNode, useEffect } from "react";
import { cartCheckInterval } from "~/hooks/useCart";

export default function CartCheckInterval(): ReactNode {
  useEffect(() => {
    const interval = setInterval(cartCheckInterval, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return null;
}
