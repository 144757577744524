"use client";

import { GoogleTagManager as GTM } from "@next/third-parties/google";
import { usePathname } from "next/navigation";
import { ReactNode, useEffect, useMemo } from "react";
import { useCookieSettings } from "~/hooks/useCookieSettings";
import { useLocale } from "~/hooks/useLocale";
import { gtmPageView } from "~/lib/client/gtm";

import { PlainMessage, proto3 } from "@bufbuild/protobuf";
import { Provider, Tracker } from "@egocentric-systems/ts-apis/shop_config/types/v1/tracking_pb";

interface IGoogleTagManager {
  trackers?: Array<PlainMessage<Tracker>>
}

const providerEnumType = proto3.getEnumType(Provider);

export default function GoogleTagManager({trackers}: Readonly<IGoogleTagManager>): ReactNode {
  const pathname = usePathname();
  const lng = useLocale();
  const { necessary, thirdParty } = useCookieSettings();

  const googleTracker = useMemo(() => {
   return trackers?.find((t) => {
     return providerEnumType.findNumber(Provider.GOOGLE)?.name === t?.provider.toLocaleString() && t?.enabled && t?.trackingId;
   });
  }, [trackers]);

  useEffect(() => {
    gtmPageView(pathname, lng);
  }, [lng, necessary, pathname, thirdParty]);

  return (googleTracker && necessary && thirdParty) && <GTM gtmId={googleTracker?.trackingId} />;
}
