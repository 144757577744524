"use client";

import type { FC, ReactNode } from "react";
import { useTranslations } from "~/hooks/useTranslations";
import { useBoolean, useMediaQuery } from "usehooks-ts";
import { Dialog, DialogContent } from "~/components/ui/dialog";
import { cn } from "~/lib/utils";
import { AddOnContent } from "./AddOnContent";
import { Button } from "~/components/ui/button";
import { useAddOnAvailable } from "~/app/cart/_components/AddOns/useAddOnAvailable";
import Link from "next/link";
import { Paths } from "~/lib/Paths";
import { useRouter } from "next/navigation";

interface IAddOns {
  continueTo: "checkout" | "cart";
  children: FC<{
    onOpen?: () => void;
  }>;
}

export function AddOnPopover({ children, continueTo }: IAddOns): ReactNode {
  const t = useTranslations();
  const router = useRouter();
  const addOnAvailable = useAddOnAvailable();
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);

  const onAddFanticket = () => {
    router.push(Paths.CART);
    close();
  };

  if (addOnAvailable && isOpen) {
    return (
      <>
        <Dialog open={isOpen}>
          <DialogContent
            hideClose
            className={cn(
              !isDesktop && "h-[100vh] max-h-[100vh]",
              "flex flex-col sm:max-w-[600px]",
            )}
          >
            <div className="space-y-4 pb-4">
              <AddOnContent onAddFanticket={onAddFanticket} />
              <div className="flex justify-between">
                <Button
                  variant="link"
                  onClick={close}
                  className="p-0 text-gray-400"
                >
                  {t.additional_options.cancel}
                </Button>
                <Link
                  href={continueTo === "checkout" ? Paths.CHECKOUT : Paths.CART}
                  prefetch={false}
                >
                  <Button
                    variant="link"
                    onClick={close}
                    className="p-0 text-gray-400"
                  >
                    {t.additional_options.continue}
                  </Button>
                </Link>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        {children({ onOpen: open })}
      </>
    );
  } else {
    return <>{children({ onOpen: addOnAvailable ? open : undefined })}</>;
  }
}
