"use client";

import { PlainMessage } from "@bufbuild/protobuf";
import { EventSeries } from "@egocentric-systems/ts-apis/booking_gateway/v1/events_pb";
import { ChangeEvent, ReactNode, useState } from "react";
import { Button } from "../../../ui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { useMediaQuery } from "usehooks-ts";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "../../../ui/dialog";
import { Input } from "../../../ui/input";
import { Paths } from "~/lib/Paths";
import Link from "next/link";
import { cn, getVariantByWidth } from "~/lib/utils";
import Image from "next/image";
import { generatePath } from "~/lib/client/utils";
import {
  gtmCloseSearch,
  gtmOpenSearch,
  gtmSearchResults,
} from "~/lib/client/gtm";
import { SearchResult } from "~/lib/definitions";
import { useTranslations } from "~/hooks/useTranslations";
import { X } from "lucide-react";

type Props = {
  series: PlainMessage<EventSeries>[];
  className?: string;
};

let timeout: NodeJS.Timeout | null = null;

export default function NavigationSearch(props: Props): ReactNode {
  const t = useTranslations();
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const [openSearch, setOpenSearch] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [results, setResults] = useState<SearchResult[]>([]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      if (!search.length) {
        setResults([]);
        gtmSearchResults(search, []);
        return;
      }

      const results: SearchResult[] = [];
      const searchValue = search.toLowerCase();

      for (let i = 0; i < props.series.length; i++) {
        const name = props.series[i].name?.toLowerCase();
        const intro = props.series[i].intro?.toLowerCase();

        if (name?.includes(searchValue) || intro?.includes(searchValue)) {
          const image = getVariantByWidth(400, props.series[i].thumbnail);

          results.push({
            name: props.series[i].name,
            type: "series",
            url: generatePath(Paths.SERIES, {
              seriesId: props.series[i].id,
            }),
            image,
          });
        }

        for (let j = 0; j < props.series[i].events.length; j++) {
          const name = props.series[i].events[j].name?.toLowerCase();
          const description =
            props.series[i].events[j].description?.toLowerCase();

          if (
            name?.includes(searchValue) ||
            description?.includes(searchValue)
          ) {
            const image = getVariantByWidth(
              400,
              props.series[i].events[j].thumbnail,
            );
            results.push({
              name: props.series[i].events[j].name,
              type: "event",
              url: generatePath(Paths.EVENT, {
                eventId: props.series[i].events[j].id,
              }),
              image,
            });
          }
        }

        for (let j = 0; j < props.series[i].bundles?.length; j++) {
          const name = props.series[i].bundles[j].name?.toLowerCase();
          const description =
            props.series[i].bundles[j].description?.toLowerCase();

          if (
            name?.includes(searchValue) ||
            description?.includes(searchValue)
          ) {
            const bundleSeries = props.series.find(
              (series) => series.id === props.series[i].bundles[j].seriesId,
            );

            const image = getVariantByWidth(400, bundleSeries?.thumbnail);
            results.push({
              name: props.series[i].bundles[j].name,
              type: "bundle",
              url: generatePath(Paths.TICKETS_BUNDLE, {
                bundleId: props.series[i].bundles[j].id,
              }),
              image,
            });
          }
        }
      }

      gtmSearchResults(search, results);
      setResults(results);
    }, 500);
  };

  const handleOpenChange = (value: boolean): void => {
    setOpenSearch(value);

    if (value) {
      gtmOpenSearch();
    } else {
      gtmCloseSearch();
    }
  };

  return (
    <Dialog open={openSearch} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Button
          className={cn("px-2 md:px-4", props.className)}
          type="button"
          variant="link"
          title={t.search}
          aria-label={t.search}
        >
          <span className="sr-only">{t.search_description}</span>
          <FontAwesomeIcon className="header-icon h-4 w-4" icon={faSearch} />
        </Button>
      </DialogTrigger>
      <DialogContent
        hideClose
        className={cn(
          !isDesktop && "h-[100vh] max-h-[100vh] py-[50px]",
          "flex flex-col sm:max-w-[600px]",
        )}
      >
        <div className="space-y-4 pb-4">
          <div className="mb-4 flex items-center justify-between">
            <DialogTitle className="text-xl font-bold">{t.search}</DialogTitle>
            <DialogClose asChild>
              <button
                className="text-black focus:outline-none"
                aria-label="Close"
              >
                <X className="size-[24px]" />
              </button>
            </DialogClose>
          </div>
          <Input
            type="search"
            value={search}
            id="egosys-shop-search"
            className="text-[16px]"
            onChange={handleSearchChange}
            placeholder={t.search_description}
          />
        </div>

        <ul className="w-full flex-grow space-y-2 overflow-y-auto">
          {results.length === 0 && (
            <li className="text-sm">
              {search.length === 0 ? t.type_to_search : t.no_search_results}
            </li>
          )}
          {results.map((res, index) => (
            <Link
              prefetch={false}
              href={res.url}
              key={index}
              className="flex hover:underline"
              onClick={() => setOpenSearch(false)}
            >
              <li className="flex flex-row flex-nowrap gap-4">
                {res.image && (
                  <Image
                    src={res.image.url}
                    width={res.image.width}
                    height={res.image.height}
                    alt={res.name}
                    className="h-12 w-12 rounded-md object-cover object-center"
                  />
                )}
                <div className="flex flex-col justify-between">
                  <p className="font-bold">{res.name}</p>
                  <p className="text-sm leading-sm text-gray-600">
                    {res.type === "series" ? t.event_series : "Event"}
                  </p>
                </div>
              </li>
            </Link>
          ))}
        </ul>
      </DialogContent>
    </Dialog>
  );
}
