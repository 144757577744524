"use client";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useRouter } from "next/navigation";
import { useLocale } from "~/hooks/useLocale";
import { gtmChangeLanguage } from "~/lib/client/gtm";
import { changeLanguage } from "~/lib/client/utils";

dayjs.extend(timezone);
dayjs.extend(utc);

type Props = {
  code: string;
  name: string;
};

export default function FooterLanguageButton(props: Props) {
  const lng = useLocale();
  const router = useRouter();

  return (
    <button
      className="inline-flex flex-nowrap gap-2 hover:underline"
      rel="alternate"
      aria-label={props.name}
      title={props.name}
      onClick={() => {
        gtmChangeLanguage(lng, props.code);
        changeLanguage(props.code);
        router.refresh();
      }}
    >
      <span>{props.name}</span>
    </button>
  );
}
