"use client";

import { useLocalStorage, useMediaQuery } from "usehooks-ts";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../ui/alert-dialog";
import Image from "next/image";
import { useTranslations } from "~/hooks/useTranslations";
import { isDtmShop } from "~/lib/client/isDtmShop";
import { Button } from "../ui/button";
import dayjs from "dayjs";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from "../ui/drawer";

const startDate = dayjs()
  .tz("CET")
  .year(2024)
  .month(11)
  .date(14)
  .startOf("day");

const endDate = dayjs().tz("CET").year(2024).month(11).date(15).endOf("day");

export function DtmAdventSpecial() {
  const [show, setShow] = useLocalStorage(
    "show-advent-special-track-safari",
    true,
  );

  const t = useTranslations();

  const isDesktop = useMediaQuery("(min-width: 768px)");

  const isBetweenStartAndEnd =
    dayjs().tz("CET").isAfter(startDate) && dayjs().tz("CET").isBefore(endDate);

  return (
    isDtmShop() &&
    isBetweenStartAndEnd &&
    (isDesktop ? (
      <AlertDialog open={show} onOpenChange={setShow}>
        <AlertDialogContent className="max-w-[700px]">
          <AlertDialogHeader>
            <AlertDialogTitle>{t.dmt.advent_special.title}</AlertDialogTitle>

            <AlertDialogDescription>
              {t.dmt.advent_special.description}
            </AlertDialogDescription>
          </AlertDialogHeader>

          <div className="flex gap-4">
            <Image
              height={200}
              width={200}
              src="/dtm_track_safari.jpg"
              alt="DTM race pass"
              className="rounded"
            />

            <div className="flex grow flex-col">
              <ul className="ml-4 grow list-['-_'] md:text-sm">
                <li>{t.dmt.advent_special.info_1}</li>
                <li>{t.dmt.advent_special.info_2}</li>
                <li>{t.dmt.advent_special.info_3}</li>
              </ul>

              <div className="flex justify-end">
                <Button onClick={() => setShow(false)}>
                  {t.dmt.advent_special.continue}
                </Button>
              </div>
            </div>
          </div>
        </AlertDialogContent>
      </AlertDialog>
    ) : (
      <Drawer open={show} onOpenChange={setShow}>
        <DrawerContent>
          <DrawerHeader className="text-left">
            <DrawerTitle>{t.dmt.advent_special.title}</DrawerTitle>

            <DrawerDescription>
              {t.dmt.advent_special.description}
            </DrawerDescription>
          </DrawerHeader>

          <div className="flex flex-col gap-4 p-4">
            <Image
              height={200}
              width={200}
              src="/dtm_track_safari.jpg"
              alt="DTM race pass"
              className="self-center rounded"
            />

            <ul className="ml-4 list-['-_']">
              <li>{t.dmt.advent_special.info_1}</li>
              <li>{t.dmt.advent_special.info_2}</li>
              <li>{t.dmt.advent_special.info_3}</li>
            </ul>

            <div className="flex justify-end pt-4">
              <Button onClick={() => setShow(false)}>
                {t.dmt.advent_special.continue}
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    ))
  );
}
