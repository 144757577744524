"use client";

import { ReactNode } from "react";
import { useMediaQuery } from "usehooks-ts";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from "../ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "../ui/drawer";
import { Switch } from "../ui/switch";

import { useCookieSettings } from "~/hooks/useCookieSettings";
import { useTranslations } from "~/hooks/useTranslations";

type CookieSettingsProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  setShowCookiesBanner: (value: boolean) => void;
};

export function CookieSettings(props: CookieSettingsProps): ReactNode {
  const t = useTranslations();
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const cookies = useCookieSettings();

  if (isDesktop) {
    return (
      <Dialog open={props.open}>
        <DialogContent hideClose className="sm:max-w-[600px]">
          <DialogTitle>{t.privacy_policy_preference_center}</DialogTitle>
          <DialogDescription>{t.information}</DialogDescription>
          <div className="flex flex-row">
            <Button
              onClick={() => {
                cookies.acceptAll();
                props.setOpen(false);
                props.setShowCookiesBanner(false);
              }}
              type="button"
            >
              {t.accept_all}
            </Button>
          </div>
          <p>
            <b>{t.handle_privacy_settings}</b>
          </p>
          <Accordion
            type="single"
            collapsible
            className="max-h-[50vh] overflow-y-auto"
          >
            <AccordionItem value="necessary">
              <AccordionTrigger
                icon={
                  <Switch
                    onClick={(e) => e.stopPropagation()}
                    checked
                    disabled
                  />
                }
                iconInFront
              >
                <span>{t.necessary_cookies}</span>
              </AccordionTrigger>
              <AccordionContent>
                <span>{t.necessary_cookies_description}</span>
                <ul className="mt-4 list-inside list-disc space-y-1">
                  <li className="space-x-2">
                    <span>
                      <b>egosys-cookie-preferences</b>
                    </span>
                    <span>{t.cookie_keys.egosys_cookie_preferences}</span>
                  </li>
                  <li className="space-x-2">
                    <span>
                      <b>egosys-cart-order-id-items</b>
                    </span>
                    <span>{t.cookie_keys.egosys_cart_order_id_items}</span>
                  </li>
                  <li className="space-x-2">
                    <span>
                      <b>egosys-cart-items</b>
                    </span>
                    <span>{t.cookie_keys.egosys_cart_items}</span>
                  </li>
                  <li className="space-x-2">
                    <span>
                      <b>egosys-cart-tokens</b>
                    </span>
                    <span>{t.cookie_keys.egosys_cart_tokens}</span>
                  </li>
                  <li className="space-x-2">
                    <span>
                      <b>egosys-customer</b>
                    </span>
                    <span>{t.cookie_keys.egosys_customer}</span>
                  </li>
                  <li className="space-x-2">
                    <span>
                      <b>egosys-shipment-method</b>
                    </span>
                    <span>{t.cookie_keys.egosys_shipment_method}</span>
                  </li>
                </ul>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="performance">
              <AccordionTrigger
                iconInFront
                icon={
                  <Switch
                    checked={cookies.performance}
                    onCheckedChange={cookies.setPerformance}
                    onClick={(e) => e.stopPropagation()}
                  />
                }
              >
                <span>Performance Cookies</span>
              </AccordionTrigger>
              <AccordionContent>
                <p>{t.performance_cookies_description}</p>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="third-party">
              <AccordionTrigger
                iconInFront
                icon={
                  <Switch
                    checked={cookies.thirdParty}
                    onCheckedChange={cookies.setThirdParty}
                    onClick={(e) => e.stopPropagation()}
                  />
                }
              >
                <span>{t.third_party_cookies}</span>
              </AccordionTrigger>
              <AccordionContent>
                {t.third_party_cookies_description}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
          <DialogFooter>
            <Button
              onClick={() => {
                cookies.acceptNecessary();
                props.setOpen(false);
                props.setShowCookiesBanner(false);
              }}
              variant="secondary"
              type="button"
            >
              {t.only_necessary}
            </Button>
            <Button
              onClick={() => {
                props.setOpen(false);
                props.setShowCookiesBanner(false);
              }}
              variant="secondary"
              type="button"
            >
              {t.save_my_settings}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={props.open} dismissible={false}>
      <DrawerContent hideBar>
        <DrawerHeader className="text-left">
          <DrawerTitle>{t.privacy_policy_preference_center}</DrawerTitle>
          <DrawerDescription>{t.information}</DrawerDescription>
          <Button
            onClick={() => {
              cookies.acceptAll();
              props.setOpen(false);
              props.setShowCookiesBanner(false);
            }}
            type="button"
          >
            {t.accept_all}
          </Button>
        </DrawerHeader>
        <Accordion className="p-4" type="single" collapsible>
          <AccordionItem value="necessary">
            <AccordionTrigger
              icon={
                <Switch onClick={(e) => e.stopPropagation()} checked disabled />
              }
              iconInFront
            >
              <span>{t.necessary_cookies}</span>
            </AccordionTrigger>
            <AccordionContent className="max-h-[20vh] overflow-y-auto">
              <span>{t.necessary_cookies_description}</span>
              <ul className="mt-4 list-inside list-disc space-y-1">
                <li className="space-x-2">
                  <span>
                    <b>egosys-cookie-preferences</b>
                  </span>
                  <span>{t.cookie_keys.egosys_cookie_preferences}</span>
                </li>
                <li className="space-x-2">
                  <span>
                    <b>egosys-cart-order-id-items</b>
                  </span>
                  <span>{t.cookie_keys.egosys_cart_order_id_items}</span>
                </li>
                <li className="space-x-2">
                  <span>
                    <b>egosys-cart-items</b>
                  </span>
                  <span>{t.cookie_keys.egosys_cart_items}</span>
                </li>
                <li className="space-x-2">
                  <span>
                    <b>egosys-cart-tokens</b>
                  </span>
                  <span>{t.cookie_keys.egosys_cart_tokens}</span>
                </li>
                <li className="space-x-2">
                  <span>
                    <b>egosys-customer</b>
                  </span>
                  <span>{t.cookie_keys.egosys_customer}</span>
                </li>
                <li className="space-x-2">
                  <span>
                    <b>egosys-shipment-method</b>
                  </span>
                  <span>{t.cookie_keys.egosys_shipment_method}</span>
                </li>
              </ul>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="performance">
            <AccordionTrigger
              iconInFront
              icon={
                <Switch
                  checked={cookies.performance}
                  onCheckedChange={cookies.setPerformance}
                  onClick={(e) => e.stopPropagation()}
                />
              }
            >
              <span>Performance Cookies</span>
            </AccordionTrigger>
            <AccordionContent>
              <p>{t.performance_cookies_description}</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="third-party">
            <AccordionTrigger
              iconInFront
              icon={
                <Switch
                  checked={cookies.thirdParty}
                  onCheckedChange={cookies.setThirdParty}
                  onClick={(e) => e.stopPropagation()}
                />
              }
            >
              <span>{t.third_party_cookies}</span>
            </AccordionTrigger>
            <AccordionContent>
              {t.third_party_cookies_description}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <DrawerFooter>
          <Button
            onClick={() => {
              cookies.acceptNecessary();
              props.setOpen(false);
              props.setShowCookiesBanner(false);
            }}
            variant="secondary"
            type="button"
          >
            {t.only_necessary}
          </Button>
          <Button
            onClick={() => {
              props.setOpen(false);
              props.setShowCookiesBanner(false);
            }}
            variant="secondary"
            type="button"
          >
            {t.save_my_settings}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
