"use client";

import { Analytics as VercelAnalytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import Head from "next/head";
import { ReactNode } from "react";

export default function Analytics(): ReactNode {
  return (
    <Head>
      <VercelAnalytics />
      <SpeedInsights />
    </Head>
  );
}
