"use client";

import { PayPalScriptOptions } from "@paypal/paypal-js";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { ReactNode } from "react";
import { useCookieSettings } from "~/hooks/useCookieSettings";

type Props = {
  children: ReactNode;
  options: PayPalScriptOptions;
};

export default function PayPalWrapper({ children, options }: Props): ReactNode {
  const { necessary } = useCookieSettings();

  if (necessary) {
    return (
      <PayPalScriptProvider options={options}>{children}</PayPalScriptProvider>
    );
  }

  return children;
}
