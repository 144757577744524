"use client";

import { PlainMessage } from "@bufbuild/protobuf";
import { useShopConfig } from "~/hooks/useShopConfig";
import { ReactNode, useEffect } from "react";
import { ShopConfiguration } from "@egocentric-systems/ts-apis/booking_gateway/v1/shop_config_pb";

interface IShopConfig {
  shopConfig?: PlainMessage<ShopConfiguration>;
}

export function ShopConfig({ shopConfig }: Readonly<IShopConfig>): ReactNode {
  const { setConfig } = useShopConfig();

  useEffect(() => {
    if (shopConfig) {
      setConfig(shopConfig);
    }
  }, [shopConfig]);

  return null;
}
