import { User } from "firebase/auth";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { handleUserChange, onAuthStateChanged } from "~/lib/client/auth";

type Response = {
  user: User | null;
  loading: boolean;
};

export function useUserSession(): Response {
  const [user, setUser] = useState<User | null>(null);
  const router = useRouter();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const onChange = () => {
      if (typeof document === "undefined") return;

      if (!document.hidden) {
        handleUserChange(user);
      }
    };

    document.addEventListener("visibilitychange", onChange);

    return () => {
      document.removeEventListener("visibilitychange", onChange);
    };
  }, [user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged((authUser) => {
      if (!user) return;
      if (user.email !== authUser?.email) {
        router.refresh();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [router, user]);

  return { user, loading };
}
