"use client";

import { ReactNode, useEffect, useState } from "react";
import { useMediaQuery } from "usehooks-ts";

import { CookieSettings } from "./CookieSettings";

import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "../ui/drawer";

import { useCookieSettings } from "~/hooks/useCookieSettings";
import { useTranslations } from "~/hooks/useTranslations";

export function Cookies(): ReactNode {
  const t = useTranslations();
  const [showCookieSettings, setShowCookieSettings] = useState<boolean>(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [showCookiesBanner, setShowCookieBanner] = useState<boolean>(false);
  const cookies = useCookieSettings();

  useEffect(() => {
    if (!cookies.necessary) {
      setShowCookieBanner(true);
    }
  }, [cookies.necessary]);

  if (isDesktop) {
    return (
      <>
        <Dialog open={showCookiesBanner && !showCookieSettings}>
          <DialogContent
            hideClose
            className="sm:max-w-[520px] md:max-w-[600px]"
          >
            <DialogHeader>
              <DialogTitle>Cookies</DialogTitle>
            </DialogHeader>
            <DialogDescription>{t.cookies_description}</DialogDescription>
            <DialogFooter>
              <Button
                onClick={() => setShowCookieSettings(true)}
                data-testid="cookie-settings-button"
                variant="outline"
                type="button"
              >
                {t.cookie_settings}
              </Button>
              <Button
                variant="outline"
                type="button"
                data-testid="only-necessary-button"
                onClick={() => {
                  cookies.acceptNecessary();
                  setShowCookieSettings(false);
                  setShowCookieBanner(false);
                }}
              >
                {t.only_necessary}
              </Button>
              <Button
                type="button"
                data-testid="accept-all-button"
                onClick={() => {
                  cookies.acceptAll();
                  setShowCookieSettings(false);
                  setShowCookieBanner(false);
                }}
              >
                {t.accept_all}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        <CookieSettings
          open={showCookieSettings}
          setShowCookiesBanner={setShowCookieBanner}
          setOpen={setShowCookieSettings}
        />
      </>
    );
  }

  return (
    <>
      <Drawer open={showCookiesBanner && !showCookieSettings}>
        <DrawerContent hideBar>
          <DrawerHeader className="text-left">
            <DrawerTitle>Cookies</DrawerTitle>
            <DrawerDescription>{t.cookies_description}</DrawerDescription>
          </DrawerHeader>
          <DrawerFooter className="pt-2">
            <Button
              onClick={() => {
                setShowCookieSettings(true);
              }}
              data-testid="cookie-settings-button"
              variant="outline"
              type="button"
            >
              {t.cookie_settings}
            </Button>
            <Button
              data-testid="only-necessary-button"
              onClick={() => {
                cookies.acceptNecessary();
                setShowCookieSettings(false);
                setShowCookieBanner(false);
              }}
              variant="outline"
              type="button"
            >
              {t.only_necessary}
            </Button>
            <Button
              data-testid="accept-all-button"
              type="button"
              onClick={() => {
                cookies.acceptAll();
                setShowCookieSettings(false);
                setShowCookieBanner(false);
              }}
            >
              {t.accept_all}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <CookieSettings
        open={showCookieSettings}
        setShowCookiesBanner={setShowCookieBanner}
        setOpen={setShowCookieSettings}
      />
    </>
  );
}
