"use client";

import Link from "next/link";
import { cn } from "~/lib/utils";
import { Button, buttonVariants } from "../../../ui/button";
import { Paths } from "~/lib/Paths";
import {
  faHand,
  faListRadio,
  faRightFromBracket,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { faUser as faUserFilled } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../ui/dropdown-menu";
import { useUserSession } from "~/hooks/useUserSession";
import { signOut } from "~/lib/client/auth";
import { useMediaQuery } from "usehooks-ts";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "../../../ui/drawer";
import { generatePath } from "~/lib/client/utils";
import { useTranslations } from "~/hooks/useTranslations";

type Props = {
  className?: string;
};

export default function NavigationUser(props: Props) {
  const t = useTranslations();
  const { user, loading } = useUserSession();
  const [open, setOpen] = useState(false);
  const [signedOutModal, setSignedOutModal] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const handleSignOut = async () => {
    await signOut();
    setSignedOutModal(true);
  };

  if (loading) {
    return (
      <div className="flex h-10 w-[44px] flex-row items-center justify-center text-sm">
        <FontAwesomeIcon
          icon={faUser}
          className="header-icon h-4 w-4 text-gray-500"
        />
      </div>
    );
  }

  return (
    <>
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            title={t.profile}
            aria-label={t.profile}
            type="button"
            variant="link"
            className={cn("px-2 md:px-4", props.className)}
          >
            <FontAwesomeIcon
              className="header-icon h-4 w-4"
              icon={Boolean(user) ? faUserFilled : faUser}
            />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="mr-4 min-w-[260px] p-4">
          {user ? (
            <>
              <DropdownMenuItem className="cursor-pointer" asChild>
                <Link
                  prefetch={false}
                  className="flex flex-row flex-nowrap items-center gap-2 leading-5"
                  href={Paths.ACCOUNT}
                  aria-label={t.profile}
                  title={t.profile}
                >
                  <FontAwesomeIcon icon={faUserFilled} />
                  <span>{t.profile}</span>
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem className="cursor-pointer" asChild>
                <Link
                  prefetch={false}
                  className="flex flex-row flex-nowrap items-center gap-2 leading-5"
                  href={Paths.ACCOUNT_ORDERS}
                  aria-label={t.my_orders}
                  title={t.my_orders}
                >
                  <FontAwesomeIcon icon={faListRadio} />
                  <span>{t.my_orders}</span>
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={handleSignOut}
                className="flex cursor-pointer flex-row flex-nowrap items-center gap-2 leading-5"
              >
                <FontAwesomeIcon icon={faRightFromBracket} />
                <span>{t.sign_out}</span>
              </DropdownMenuItem>
            </>
          ) : (
            <>
              <DropdownMenuItem className="cursor-pointer" asChild>
                <Link
                  prefetch={false}
                  className="flex flex-row flex-nowrap items-center gap-2 leading-5"
                  href={Paths.SIGN_IN}
                  title={t.sign_in}
                  aria-label={t.sign_in}
                >
                  {t.sign_in}
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem className="cursor-pointer" asChild>
                <Link
                  prefetch={false}
                  className="flex flex-row flex-nowrap items-center gap-2 leading-5"
                  href={Paths.SIGN_UP}
                  title={t.sign_up}
                  aria-label={t.sign_up}
                >
                  {t.sign_up}
                </Link>
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      {isDesktop ? (
        <Dialog open={signedOutModal}>
          <DialogContent hideClose>
            <DialogHeader>
              <DialogTitle>
                <FontAwesomeIcon icon={faHand} className="mr-2" />
                {t.signed_out}
              </DialogTitle>
            </DialogHeader>
            <DialogDescription className="sr-only">
              {t.signed_out}
            </DialogDescription>
            <DialogFooter className="w-full sm:justify-between">
              <Link
                href={generatePath(Paths.ROOT)}
                className={buttonVariants({ variant: "outline" })}
                onClick={() => {
                  setSignedOutModal(false);
                }}
                prefetch={false}
              >
                {t.back_to_home}
              </Link>
              <Link
                href={Paths.SIGN_IN}
                className={buttonVariants({ variant: "outline" })}
                onClick={() => {
                  setSignedOutModal(false);
                }}
                prefetch={false}
              >
                {t.sign_in}
              </Link>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      ) : (
        <Drawer open={signedOutModal}>
          <DrawerContent hideBar draggable={false}>
            <DrawerHeader>
              <DrawerTitle>
                <FontAwesomeIcon icon={faHand} className="mr-2" />
                {t.signed_out}
              </DrawerTitle>
            </DrawerHeader>
            <DrawerDescription className="sr-only">
              {t.signed_out}
            </DrawerDescription>
            <DrawerFooter className="justify-between">
              <Link
                href={Paths.ROOT}
                className={buttonVariants({ variant: "outline" })}
                onClick={() => {
                  setSignedOutModal(false);
                }}
                prefetch={false}
              >
                {t.back_to_home}
              </Link>
              <Link
                href={Paths.SIGN_IN}
                className={buttonVariants({ variant: "outline" })}
                onClick={() => {
                  setSignedOutModal(false);
                }}
                prefetch={false}
              >
                {t.sign_in}
              </Link>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
}
