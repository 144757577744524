"use client";

import { useCookieSettings } from "~/hooks/useCookieSettings";
import { useTranslations } from "~/hooks/useTranslations";

export default function FooterCookieSettings() {
  const t = useTranslations();
  const { remove } = useCookieSettings();

  return (
    <button
      className="text-left hover:underline"
      type="button"
      onClick={remove}
    >
      {t.cookieSettings}
    </button>
  );
}
