"use client";

import { PropsWithChildren } from "react";
import { isDtmShop } from "~/lib/client/isDtmShop";
import { cn } from "~/lib/utils";

export function ThemeWrapper({ children }: PropsWithChildren) {
  const dtmShop = isDtmShop();

  return (
    <div className={cn(dtmShop && "font-univia [&_*]:font-univia")}>
      {children}
    </div>
  );
}
