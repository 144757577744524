"use client";

import { Button } from "~/components/ui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/pro-regular-svg-icons";
import { AddOnPopover } from "~/app/cart/_components/AddOns";
import NavigationCartAmount from "./CartAmount";
import { usePathname, useRouter } from "next/navigation";
import { Paths } from "~/lib/Paths";
import { alreadySomeFanticketUpgrades } from "~/lib/utils";
import { useCart } from "~/hooks/useCart";
import { useTranslations } from "~/hooks/useTranslations";

export function CartLink() {
  const router = useRouter();
  const { items } = useCart();
  const t = useTranslations();
  const pathname = usePathname();

  const handleGoToNextPage = (onOpen: (() => void) | undefined) => {
    if (
      onOpen !== undefined &&
      pathname !== Paths.CART &&
      !alreadySomeFanticketUpgrades(items)
    ) {
      onOpen();
    } else {
      router.push(Paths.CART);
    }
  };

  return (
    <AddOnPopover continueTo={"cart"}>
      {({ onOpen }) => (
        <Button
          variant="link"
          onClick={() => {
            handleGoToNextPage(onOpen);
          }}
          className="relative h-1.5 justify-start px-0 px-2 py-0 text-left text-xs font-bold text-white hover:underline md:px-4"
        >
          <div>
            <FontAwesomeIcon
              className="header-icon h-4 w-4 text-black"
              icon={faCartShopping}
            />
            <span className="sr-only">{t.cart_title}</span>
            <NavigationCartAmount />
          </div>
        </Button>
      )}
    </AddOnPopover>
  );
}
