"use client";

import { ReactNode, useMemo } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../ui/dropdown-menu";
import { Button } from "../../../ui/button";
import { useLocale } from "~/hooks/useLocale";
import { usePathname, useRouter } from "next/navigation";
import { cn } from "~/lib/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faGlobe } from "@fortawesome/pro-regular-svg-icons";
import { changeLanguage } from "~/lib/client/utils";

type Props = {
  languages: { code: string; name: string }[];
  showLabel?: boolean;
  text?: boolean;
  className?: string;
};

export default function NavigationLanguages(props: Props): ReactNode {
  const locale = useLocale();
  const pathname = usePathname();
  const router = useRouter();

  const languages = useMemo(
    () =>
      props.languages
        .map((l) => {
          return {
            targetUrl: `${pathname}?lng=${l.code}`,
            code: l.code,
            visibleName: l.name,
          };
        })
        .filter(Boolean)
        .sort((a, b) => {
          return a.visibleName.localeCompare(b.visibleName);
        }),
    [props.languages, pathname],
  );

  const currentLanguage = useMemo(() => {
    return props.languages.find((l) => l.code === locale);
  }, [locale, props.languages]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          type="button"
          variant="link"
          className={cn(
            props.text !== undefined &&
              "h-auto px-2 py-0 hover:underline md:px-4",
            props.className,
          )}
        >
          <span className="sr-only">
            {currentLanguage?.name} {currentLanguage?.code}
          </span>
          <FontAwesomeIcon className="header-icon h-4 w-4" icon={faGlobe} />
          {props.showLabel && (
            <span className="text-sm">{currentLanguage?.name}</span>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mr-4 min-w-[260px] p-4">
        {languages.map((lng) => {
          return (
            <DropdownMenuItem
              onClick={() => {
                changeLanguage(lng.code);
                router.refresh();
              }}
              key={lng.code}
              className="flex cursor-pointer flex-row items-center justify-between"
            >
              <span>{lng.visibleName}</span>
              {locale === lng.code && <FontAwesomeIcon icon={faCheck} />}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
