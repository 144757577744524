"use client";

import {
  getAuth,
  signInWithEmailAndPassword as _signInWithEmailAndPassword,
  confirmPasswordReset as _confirmPasswordReset,
  onAuthStateChanged as _onAuthStateChanged,
  onIdTokenChanged as _onIdTokenChanged,
  signInWithCustomToken as _signInWithCustomToken,
  updatePassword as _updatePassword,
  verifyPasswordResetCode as _verifyPasswordResetCode,
  updateEmail as _updateEmail,
  User,
  NextOrObserver,
  UserCredential,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { initializeApp } from "@firebase/app";
import Cookies from "universal-cookie";
import { EGOSYS_AUTH_TOKEN } from "../definitions";
import { z } from "zod";
import { SignInSchema } from "./schemas/sign-in";

export const auth = getAuth(
  initializeApp(
    {
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    },
    process.env.NEXT_PUBLIC_API_GATEWAY,
  ),
);

export function handleUserChange(user: User | null) {
  const cookies = new Cookies();

  if (!user) {
    cookies.remove(EGOSYS_AUTH_TOKEN, {
      path: "/",
      sameSite: "strict",
    });
  } else {
    user.getIdToken().then((token) => {
      cookies.set(EGOSYS_AUTH_TOKEN, token, {
        path: "/",
        sameSite: "strict",
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7 * 4 * 12),
        maxAge: 60 * 60 * 24 * 7 * 4 * 12,
      });
    });
  }
}

_onAuthStateChanged(auth, (user) => {
  handleUserChange(user);
});

_onIdTokenChanged(auth, (user) => {
  handleUserChange(user);
});

window.addEventListener("focus", () => {
  if (auth.currentUser) {
    auth.currentUser.reload();
  }
});

window.addEventListener("blur", () => {
  if (auth.currentUser) {
    auth.currentUser.reload();
  }
});

window.addEventListener("online", () => {
  if (auth.currentUser) {
    auth.currentUser.reload();
  }
});

window.addEventListener("offline", () => {
  if (auth.currentUser) {
    auth.currentUser.reload();
  }
});

export async function signOut(): Promise<void> {
  const cookies = new Cookies();
  cookies.remove(EGOSYS_AUTH_TOKEN, {
    path: "/",
    sameSite: "strict",
  });

  await auth.signOut();
}

export function onAuthStateChanged(cb: NextOrObserver<User>) {
  return _onAuthStateChanged(auth, cb);
}

export function onIdTokenChanged(cb: NextOrObserver<User>) {
  return _onIdTokenChanged(auth, cb);
}

export async function updatePassword(
  tenant: string,
  currentPassword: string,
  newPassword: string,
) {
  if (!auth.currentUser?.email) {
    throw new Error("User is not signed in");
  }

  auth.tenantId = tenant;

  await _signInWithEmailAndPassword(
    auth,
    auth.currentUser.email,
    currentPassword,
  );

  await _updatePassword(auth.currentUser, newPassword);
}

export async function resetPassword(
  tenant: string,
  password: string,
  token?: string,
): Promise<string> {
  if (typeof token === "string" && token.length > 0 && auth.currentUser) {
    throw new Error("User is already signed in");
  }

  auth.tenantId = tenant;

  if (typeof token === "string" && token.length > 0) {
    const email = await _verifyPasswordResetCode(auth, token);
    await _confirmPasswordReset(auth, token, password);
    return email;
  }

  if (!auth.currentUser?.email) {
    throw new Error("User is not signed in");
  }

  await _updatePassword(auth.currentUser, password);

  return auth.currentUser.email;
}

export async function signInWithEmailAndPassword(
  data: z.infer<SignInSchema>,
  tenantId: string,
): Promise<UserCredential> {
  auth.tenantId = tenantId;

  const persistance = data.staySignedIn
    ? browserLocalPersistence
    : browserSessionPersistence;

  setPersistence(auth, persistance);

  const result = await _signInWithEmailAndPassword(
    auth,
    data.email,
    data.password,
  );

  return result;
}
